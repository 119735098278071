@import 'styles/variables';
@import 'styles/mixins';

.Navigation {
  display: flex;
  align-items: center;
  &_menu {
    width: 100%;
    padding: $unit * 2 0;
    flex-direction: column;
    border-top: 1px solid rgba($greyish, 0.2);
    border-bottom: 1px solid rgba($greyish, 0.2);
    margin-bottom: $unit * 5;
    @include xs {
      margin-bottom: $unit * 3;
    }
  }

  &-Link {
    display: block;
    padding: $unit + $unit / 2;
    font-size: 10px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    .Navigation_menu & {
      font-size: 16px;
      letter-spacing: 1.3px;
      font-weight: 300;
      padding: $unit * 2;
    }
  }
}
