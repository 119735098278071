body {
  font-family: $font-family;
  font-size: 16px;
  line-height: 1.33;
  color: $black;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

#root {
  overflow: hidden;
  padding-top: $unit * 7;
  @include md {
    padding-top: $mobile-padding;
  }
  @include xs {
    padding-top: $unit * 2;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.17;
  margin: 0;
}

p,
figure {
  margin: 0;
}

b {
  font-weight: normal;
}

ul,
nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
