@import 'styles/variables';
@import 'styles/mixins';

.Awards {
  padding-top: $unit * 8;
  margin-bottom: $unit * 6;
  @include sm {
    padding-top: $unit * 6;
    margin-bottom: $unit * 4;
  }

  .swiper-container {
    padding-left: $unit * 5;
    padding-right: $unit * 5;
    padding-bottom: $unit * 10;
    @include sm {
      padding-left: $unit * 3;
      padding-right: $unit * 3;
    }
  }
}

.Awards-Item {
  box-sizing: border-box;
  min-height: 516px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  color: white;
  border-radius: 12px;
  padding: $unit * 6 12%;
  background: center / cover no-repeat;
  &_color_grey {
    background-image: url('../../assets/img/awards/texture-grey.jpg');
    @include image-2x('../../assets/img/awards/texture-grey@2x.jpg');
    background-color: $pinkish-grey;
    box-shadow: 0 50px 40px -40px $pinkish-grey;
    a {
      background-color: rgba(black, 0.2);
      &:hover {
        background-color: rgba(black, 0.1);
      }
    }
  }
  &_color_purple {
    background-image: url('../../assets/img/awards/texture-purple.jpg');
    @include image-2x('../../assets/img/awards/texture-purple@2x.jpg');
    background-color: $dark-lavender;
    box-shadow: 0 50px 40px -40px $dark-lavender;
  }
  &_color_grass {
    background-image: url('../../assets/img/awards/texture-grass.jpg');
    @include image-2x('../../assets/img/awards/texture-grass@2x.jpg');
    background-color: $pale-olive;
    box-shadow: 0 50px 40px -40px $pale-olive;
  }
  &_color_green {
    background-image: url('../../assets/img/awards/texture-green.jpg');
    @include image-2x('../../assets/img/awards/texture-green@2x.jpg');
    background-color: $greenish-grey;
    box-shadow: 0 50px 40px -40px $greenish-grey;
  }
}

.Awards-Type {
  text-transform: uppercase;
  letter-spacing: 1.6px;
  margin-bottom: $unit * 3;
}

.Awards-Image {
  width: 100%;
  height: auto;
}

.Awards-Title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-transform: uppercase;
  height: 96px;
  @include sm {
    font-size: 22px;
  }
  @include sm {
    font-size: 20px;
  }
}

.Awards-Text {
  margin-bottom: $unit * 3;
  @include sm {
    font-size: 15px;
  }
}

.Awards-Description {
  font-weight: 300;
}
