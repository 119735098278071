@import 'styles/variables';
@import 'styles/mixins';

.About {
  &-Item {
    position: relative;
    &::after {
      @include pseudo();
      top: 49px;
      width: 50%;
      border-bottom: 2px dashed rgba($greyish, 0.3);
      right: -25%;
      @include sm {
        top: 40px;
      }
      @include xs {
        width: 0;
        height: 30px;
        border-bottom: 0;
        right: 50%;
        top: auto;
        bottom: -15px;
        border-right: 1px dashed rgba($greyish, 0.5);
      }
    }
    &:last-child::after {
      display: none;
    }
  }

  &-Feature {
    position: relative;
    text-align: center;
    max-width: 180px;
    margin: auto;
    @include xs {
      padding: $unit * 2 0;
    }
    &::before {
      @include pseudo();
      display: inline-block;
      position: relative;
      background: center / contain no-repeat;
      width: 60px;
      height: 60px;
      margin-bottom: $unit * 3;
      @include sm {
        width: 42px;
        height: 42px;
        margin-bottom: $unit * 2;
      }
    }
    &_1::before {
      background-image: url('../../assets/img/about/feature-1_lg.png');
      @include image-2x('../../assets/img/about/feature-1_lg@2x.png');
      @include sm {
        background-image: url('../../assets/img/about/feature-1_md.png');
        @include image-2x('../../assets/img/about/feature-1_md@2x.png');
      }
    }
    &_2::before {
      background-image: url('../../assets/img/about/feature-2_lg.png');
      @include image-2x('../../assets/img/about/feature-2_lg@2x.png');
      @include sm {
        background-image: url('../../assets/img/about/feature-2_md.png');
        @include image-2x('../../assets/img/about/feature-2_md@2x.png');
      }
    }
    &_3::before {
      background-image: url('../../assets/img/about/feature-3_lg.png');
      @include image-2x('../../assets/img/about/feature-3_lg@2x.png');
      @include sm {
        background-image: url('../../assets/img/about/feature-3_md.png');
        @include image-2x('../../assets/img/about/feature-3_md@2x.png');
      }
    }
    &_4::before {
      background-image: url('../../assets/img/about/feature-4_lg.png');
      @include image-2x('../../assets/img/about/feature-4_lg@2x.png');
      @include sm {
        background-image: url('../../assets/img/about/feature-4_md.png');
        @include image-2x('../../assets/img/about/feature-4_md@2x.png');
      }
    }
  }

  &-FeatureTitle {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: $unit * 2;
    @include sm {
      margin-bottom: $unit + $unit / 2;
      font-size: 14px;
      letter-spacing: 0.4px;
    }
  }

  &-FeatureText {
    font-size: 14px;
    color: $brownish-grey;
    font-weight: 300;
    @include sm {
      font-size: 12px;
    }
  }
}
