@import 'styles/variables';

.Social {
  display: inline-flex;
  @media (max-width: 759px) {
    margin-bottom: $unit * 6;
    justify-content: center;
  }
  &_menu {
    margin-bottom: $unit * 10;
    @media (max-width: 759px) {}
  }

  &-Item {
    display: block;
    height: 100%;
    &:not(:last-child) {
      margin-right: $unit * 2;
    }
  }

  &-Link {
    display: inline-block;
  }
}
