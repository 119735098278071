@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.FadeIn {
  animation: fadeIn $longer $ease-in-out both;
}

@keyframes fadeInTopSmall {
  from {
    transform: translate3d(0, -10%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInTop {
  from {
    transform: translate3d(0, -50%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    transform: translate3d(-25%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    transform: translate3d(25%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInBottomSmall {
  from {
    transform: translate3d(0, 10%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInBottom {
  from {
    transform: translate3d(0, 25%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInCenterBottom {
  from {
    transform: translate3d(-50%, 25%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(-50%, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInScreenLeft {
  from {
    transform: translate3d(-15%, -50%, 0) rotate(-20deg);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0) rotate(0);
    opacity: 1;
  }
}

@keyframes fadeInScreenRight {
  from {
    transform: translate3d(15%, -50%, 0) rotate(20deg);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0) rotate(0);
    opacity: 1;
  }
}