@import 'styles/variables';
@import 'styles/mixins';

.Header {
  position: relative;
  z-index: 10;
  transition: background-color $medium $ease-in-out;
  &_sticky {
    top: 0;
    position: fixed;
    width: 100vw;
    background-color: rgba($background, 0.98);
    box-shadow: 0 2px 3px rgba(black, 0.13);
    transition: background-color $medium $ease-in-out, box-shadow $medium $ease-in-out;
    & + main {
      padding-top: 80px;
    }
    @include sm {
      & + main {
        padding-top: 62px;
      }
    }
    @include xs {
      & + main {
        padding-top: 52px;
      }
    }
  }

  &-Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-LogoBlock {
    display: flex;
    align-items: center;
  }

  &-LogoImgBlock {
    width: 80px;
    height: 80px;
    border-radius: 16px;
    box-shadow: 0 5px 6px 0 rgba(black, 0.08), 0 2px 30px 0 rgba(black, 0.13);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $unit * 2;
    transition: transform $short $ease-in-out;
    .Header_sticky & {
      transform: scale(0.65);
    }
    @include sm {
      width: 62px;
      height: 62px;
      border-radius: 14px;
      .Header_sticky & {
        transform: scale(0.7);
      }
    }
    @include xs {
      border-radius: 10px;
      width: 48px;
      height: 48px;
    }
  }

  &-LogoImg {
    width: 30px;
    @include sm {
      width: 24px;
    }
    @include xs {
      width: 20px;
    }
  }

  &-Title {
    letter-spacing: 1.3px;
    color: $greyish-brown;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: normal;
    transition: transform $short $ease-in-out;
    .Header_sticky & {
      transform: translateX(-$unit * 2);
    }
    @include sm {
      letter-spacing: 1.2px;
      font-size: 14px;
      .Header_sticky & {
        transform: translateX(-12px);
      }
    }
    @include xs {
      letter-spacing: 1px;
      font-size: 12px;
    }
  }
}
