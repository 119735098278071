@import 'styles/variables';
@import '../../../styles/mixins';

.Menu {
  position: relative;
  background-color: $background;
  height: 100vh;
  padding-top: $unit * 10;
  padding-left: $mobile-padding;
  padding-right: $mobile-padding;
  overflow-y: auto;
  @include xs {
    padding-top: $unit * 5;
  }

  &-Content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &-MainContent {
    width: 100%;
    margin-bottom: $unit * 5;
    text-align: center;
    @include xs {
      margin-bottom: $unit * 3;
    }
  }

  &-Logo {
    margin-bottom: $unit * 5;
    @include xs {
      margin-bottom: $unit * 3;
    }
  }

  &-Title {
    color: $greyish-brown;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-size: 10px;
  }
}
