.VisuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  bottom: 0;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.Container {
  margin: auto;
  padding-left: $padding;
  padding-right: $padding;
  max-width: $container-width;
  @include xs {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}

.Section {
  margin-bottom: $unit * 8;
}

.Title {
  font-size: 16px;
  letter-spacing: 0.8px;
  text-align: center;
  color: $greyish;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: $unit * 4;
  &_left {
    text-align: left;
  }
  @include sm {
    font-size: 14px;
    letter-spacing: 0.4px;
  }
  @include xs {
    margin-bottom: $unit * 3;
  }
}

.Link {
  color: $greyish;
  text-decoration: none;
  transition: color $shortest $ease-in-out;
  &:hover,
  &:focus {
    outline: none;
    color: $mauve;
  }
  &_white {
    color: white;
    &:hover,
    &:focus {
      outline: none;
      color: $black;
    }
  }
  &_underline {
    text-decoration: underline;
  }
}

.Icon {
  display: block;
  & > path {
    fill: currentColor;
  }
  &_noShrink {
    flex-shrink: 0;
  }
}

.Pattern {
  padding-top: $unit * 12;
  color: white;
  background: url('../assets/img/pattern.jpg') center / 159px 159px repeat $black-two;
  @include image-2x('../assets/img/pattern@2x.jpg');
  @include sm {
    padding-top: $unit * 8;
  }
}

.Bullets {
  text-align: center;
  .swiper-pagination-bullet {
    display: inline-flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 1;
    background-color: transparent;
    transition: background-color $standard $ease-in-out, box-shadow $standard $ease-in-out;
    &:not(:last-child) {
      margin-right: $unit + $unit / 4 !important;
    }
    &::before {
      @include pseudo();
      position: relative;
      margin: auto;
      background-color: rgba($greyish-brown, 0.5);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      transition: background-color $short $ease-in-out;
    }
    &-active,
    &:hover,
    &:focus {
      outline: none;
      background-color: $greyish-brown;
    }
  }
}
