@import 'styles/variables';
@import 'styles/mixins';

.Mission {
  position: relative;
  &::before {
    @include pseudo();
    left: 0;
    bottom: 70px;
    width: 121px;
    height: 229px;
    z-index: 1;
    background: url('../../assets/img/mission/spots-1.png') center / contain no-repeat;
    @include image-2x('../../assets/img/mission/spots-1@2x.png');
    @include sm {
      bottom: auto;
      top: 110px;
      left: -10px;
    }
    @include xs {
      width: 41px;
      height: 78px;
      top: 0;
      left: 0;
      background-image: url('../../assets/img/mission/spots-1_sm.png');
      @include image-2x('../../assets/img/mission/spots-1_sm@2x.png');
    }
  }
  &::after {
    @include pseudo();
    right: 0;
    bottom: 0;
    width: 197px;
    height: 227px;
    z-index: 1;
    background: url('../../assets/img/mission/spots-2.png') center / contain no-repeat;
    @include image-2x('../../assets/img/mission/spots-2@2x.png');
    @include xs {
      width: 65px;
      height: 75px;
      background-image: url('../../assets/img/mission/spots-2_sm.png');
      @include image-2x('../../assets/img/mission/spots-2_sm@2x.png');
    }
  }

  &-FirstSalad {
    position: absolute;
    width: 201px;
    height: 296px;
    left: 0;
    top: 0;
    background: url('../../assets/img/mission/salad-1.png') center / contain no-repeat;
    @include image-2x('../../assets/img/mission/salad-1@2x.png');
    @include sm {
      left: -100px;
    }
    @include xs {
      width: 65px;
      height: 96px;
      top: -50px;
      left: 0;
      background-image: url('../../assets/img/mission/salad-1_sm.png');
      @include image-2x('../../assets/img/mission/salad-1_sm@2x.png');
    }
  }

  &-SecondSalad {
    position: absolute;
    width: 240px;
    height: 257px;
    right: 0;
    bottom: 50px;
    background: url('../../assets/img/mission/salad-2.png') center / contain no-repeat;
    @include image-2x('../../assets/img/mission/salad-2@2x.png');
    @include sm {
      right: -70px;
    }
    @include xs {
      width: 63px;
      height: 68px;
      right: 0;
      bottom: 50px;
      background-image: url('../../assets/img/mission/salad-2_sm.png');
      @include image-2x('../../assets/img/mission/salad-2_sm@2x.png');
    }
    &_mobile {
      right: -50px;
      @include sm {
        right: -140px;
      }
    }
  }

  &-Content {
    &::after {
      @include pseudo();
      position: relative;
      width: 102px;
      height: 100px;
      margin-left: auto;
      margin-right: auto;
      margin-top: $unit * 4;
      background: url('../../assets/img/mission/pinecone.png') center / contain no-repeat;
      @include image-2x('../../assets/img/mission/pinecone@2x.png');
    }
  }

  &-TitleText {
    font-size: 40px;
    color: $greyish-brown;
    text-align: center;
    max-width: 450px;
    line-height: 1;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $unit * 4;
    & > b {
      font-weight: 300;
    }
    @include sm {
      font-size: 36px;
    }
    @include xs {
      font-size: 32px;
      margin-bottom: $unit * 3;
    }
  }

  &-Text {
    font-size: 16px;
    color: rgba($black-two, 0.7);
    font-weight: 300;
    line-height: 1.5;
    @include sm {
      font-size: 15px;
    }
    &_second {
      @include xs {
        margin-top: -20px;
      }
    }
  }
}
