@import 'styles/variables';
@import 'styles/mixins';

.FeaturesSwiper {
  margin-left: -$mobile-padding;
  margin-right: -$mobile-padding;

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    opacity: 0.5;
    transition: opacity $medium $ease-in-out;
    &-active {
      opacity: 1;
    }
  }

  .Bullets {
    position: relative;
    top: 430px;
  }

  &-Slide {
    width: 33.3%;
    cursor: default;
  }

  &-SlideTitle {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: normal;
    color: $greyish-brown;
    line-height: 1;
    margin-bottom: $unit + $unit / 2;
  }

  &-SlideTitleText {
    margin-left: $unit;
  }

  &-SlideText {
    font-size: 12px;
    font-weight: 300;
    color: $brownish-grey;
  }

  &-Block {
    position: relative;
    margin-top: -20px;
    height: 467px;
    background: url('../../../assets/img/features/fruits_sm@2x.png') center 20px / 349px 395px no-repeat;
  }

  &-Phone {
    position: absolute;
    width: 228px;
    height: 370px;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    &::before {
      @include pseudo();
      top: 31px;
      left: 48px;
      width: 138px;
      height: 300px;
      border-radius: 10px;
      transition: background-image $standard $ease-in-out;
      background: url('../../../assets/img/features/main_sm@2x.jpg') center / contain no-repeat white;
    }
    &_active_1::before {
      background-image: url('../../../assets/img/features/list_sm@2x.jpg');
    }
    &_active_2::before {
      background-image: url('../../../assets/img/features/search_sm@2x.jpg');
    }
    &_active_3::before {
      background-image: url('../../../assets/img/features/favourites_sm@2x.jpg');
    }
    &_active_4::before {
      background-image: url('../../../assets/img/features/orders_sm@2x.jpg');
    }
    &_active_5::before {
      background-image: url('../../../assets/img/features/map_sm@2x.jpg');
    }
    &_active_6::before {
      background-image: url('../../../assets/img/features/filters_sm@2x.jpg');
    }
    &_active_7::before {
      background-image: url('../../../assets/img/features/review_sm@2x.jpg');
    }
    &_active_8::before {
      background-image: url('../../../assets/img/features/sale-items_sm@2x.jpg');
    }
    &::after {
      @include pseudo();
      width: 100%;
      height: 100%;
      left: -20px;
      background: url('../../../assets/img/features/iphone_sm.png') center / contain no-repeat;
      @include image-2x('../../../assets/img/features/iphone_sm@2x.png');
    }
  }
}
