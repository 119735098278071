@import 'styles/variables';
@import 'styles/mixins';

.SupportedBy {
  padding-bottom: $unit * 6;
  @include sm {
    padding-bottom: 0;
  }
}

.SupportedBy-List {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: $unit * 3;
  text-align: center;
  @include md {
    grid-template-columns: repeat(3, 1fr);
  }
  @include xs {
    grid-template-columns: auto;
  }
}

.SupportedBy-Image {
  max-width: 100%;
  height: auto;
}

.SupportedBy-Title,
.SupportedBy-Link {
  font-size: 12px;
  font-weight: 300;
  color: $brownish-grey;
}

.SupportedBy-Link {
  .SupportedBy & {
    color: $brownish-grey;
  }
}
