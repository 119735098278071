@import 'styles/variables';
@import 'styles/mixins';

.Particularities {
  &-ItemBlock {
    text-align: center;
    max-width: 190px;
    margin: auto;
    @include sm {
      .Particularities-Item:first-child > &,
      .Particularities-Item:nth-child(2) > & {
        margin-bottom: $unit * 8;
      }
    }
    @include xs {
      .Particularities-Item:nth-child(3) > & {
        margin-bottom: $unit * 8;
      }
    }
  }

  &-ItemIcon {
    height: 61px;
    margin-bottom: $unit * 2;
  }

  &-ItemTitle {
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: $unit;
    @include xs {
      font-size: 15px;
    }
  }

  &-ItemText {
    font-size: 14px;
    color: rgba(white, 0.7);
    font-weight: 300;
    @include xs {
      font-size: 13px;
    }
  }
}
