@import 'styles/variables';
@import 'styles/mixins';

.Eateries {
  &-TitleText {
    max-width: 340px;
    font-size: 40px;
    line-height: 1.1;
    margin-bottom: $unit * 4;
    & > b {
      font-weight: 300;
    }
    @include sm {
      margin-bottom: $unit * 3;
      font-size: 36px;
    }
    @include xs {
      font-size: 32px;
    }
  }

  &-Text {
    margin-bottom: $unit * 4;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: rgba(white, 0.7);
    &:last-of-type {
      margin-bottom: $unit * 5;
    }
    @include sm {
      margin-bottom: $unit * 3;
      font-size: 15px;
      &:last-of-type {
        margin-bottom: $unit * 4;
      }
    }
  }

  &-Img {
    height: 100%;
    border-radius: 4px;
    background: url('../../assets/img/eateries/eatery.jpg') center / cover no-repeat white;
    @include image-2x('../../assets/img/eateries/eatery@2x.jpg');
    @include sm {
      height: 536px;
    }
    @include xs {
      margin-left: -$mobile-padding;
      margin-right: -$mobile-padding;
      border-radius: 0;
      height: 400px;
      background-image: url('../../assets/img/eateries/eatery_sm.jpg');
      @include image-2x('../../assets/img/eateries/eatery_sm@2x.jpg');
    }
  }
}
