@import 'styles/variables';
@import 'styles/mixins';

.StoreButtons {
  display: flex;
  &_contacts {
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 759px) {
      align-items: center;
      margin-bottom: $unit * 4;
    }
  }
  @include xs {
    flex-direction: column;
    align-items: center;
  }
}
