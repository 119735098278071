@import 'styles/variables';
@import 'styles/mixins';

.Banner {
  height: 802px - 52px;
  margin-top: $unit * 3;
  @include sm {
    height: 529px - 30px;
  }
  @include xs {
    height: auto;
    margin-bottom: $unit * 8;
  }

  &-Content {
    position: relative;
    padding-top: $unit * 10;
    @include sm {
      padding-top: $unit * 8;
    }
    @include xs {
      padding-top: $unit * 2;
    }
  }

  &-Blot {
    position: absolute;
    width: 974px;
    height: 802px;
    left: 30%;
    top: -52px;
    background: url('../../assets/img/banner/blot_lg.png') center / contain no-repeat;
    @include image-2x('../../assets/img/banner/blot_lg@2x.png');
    @include md {
      left: 27%;
    }
    @include sm {
      top: -30px;
      left: auto;
      right: 0;
      width: 488px;
      height: 529px;
      background-image: url('../../assets/img/banner/blot_md.png');
      @include image-2x('../../assets/img/banner/blot_md@2x.png');
    }
    @media (max-width: 799px) {
      right: -150px;
    }
    @include xs {
      width: calc(100% + 60px);
      position: relative;
      right: $mobile-padding;
      top: auto;
      background-size: auto 100%;
      background-position: right;
    }
  }

  &-FirstPhone {
    position: absolute;
    top: 200px;
    left: 230px;
    width: 265px;
    height: 489px;
    background: url('../../assets/img/banner/iphone-1_lg.png') center / contain no-repeat;
    @include image-2x('../../assets/img/banner/iphone-1_lg@2x.png');
    @include sm {
      top: 105px;
      left: 80px;
      width: 194px;
      height: 360px;
      background-image: url('../../assets/img/banner/iphone-1_md.png');
      @include image-2x('../../assets/img/banner/iphone-1_md@2x.png');
    }
    @media (max-width: 799px) {
      left: 124px;
    }
    @include xs {
      width: 155px;
      height: 285px;
      left: calc(50% - 125px);
      background-image: url('../../assets/img/banner/iphone-1_sm.png');
      @include image-2x('../../assets/img/banner/iphone-1_sm@2x.png');
    }
  }

  &-SecondPhone {
    position: absolute;
    top: 40px;
    right: 310px;
    width: 258px;
    height: 472px;
    background: url('../../assets/img/banner/iphone-2_lg.png') center / contain no-repeat;
    @include image-2x('../../assets/img/banner/iphone-2_lg@2x.png');
    @include sm {
      top: 20px;
      right: 80px;
      width: 188px;
      height: 347px;
      background-image: url('../../assets/img/banner/iphone-2_md.png');
      @include image-2x('../../assets/img/banner/iphone-2_md@2x.png');
    }
    @media (max-width: 799px) {
      right: 130px;
    }
    @include xs {
      width: 151px;
      height: 273px;
      right: calc(50% - 125px);
      transform: translateX(10px);
      background-image: url('../../assets/img/banner/iphone-2_sm.png');
      @include image-2x('../../assets/img/banner/iphone-2_sm@2x.png');
    }
  }

  &-Title {
    max-width: 409px;
    margin-bottom: $unit * 2;
    font-size: 56px;
    line-height: 1;
    & > b {
      font-weight: 300;
    }
    @include sm {
      font-size: 48px;
      max-width: 360px;
    }
    @include xs {
      font-size: 44px;
    }
  }

  &-Text {
    max-width: 296px;
    font-size: 18px;
    color: $brownish-grey;
    font-weight: 300;
    margin-bottom: $unit * 3;
    @include sm {
      font-size: 16px;
      max-width: 270px;
    }
    @include xs {
      font-size: 15px;
      margin-bottom: $unit;
    }
  }
}
