@mixin pseudo($content: '', $display: block, $pos: absolute) {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin image-2x($image) {
  @media (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
    background-image: url($image);
  }
}

@mixin xs {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 959px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 1099px) {
    @content;
  }
}
