@import 'styles/variables';
@import 'styles/mixins';

.FeaturesList {
  position: relative;
  height: 728px;
  background: url('../../../assets/img/features/fruits@2x.png') calc(50% - 10px) 30px / 549px 621px no-repeat;

  &-Phone {
    position: absolute;
    width: 358px;
    height: 584px;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    &::before {
      @include pseudo();
      top: 49px;
      left: 70px;
      width: 216px;
      height: 472px;
      border-radius: 20px;
      transition: background-image $standard $ease-in-out;
      background: url('../../../assets/img/features/main@2x.jpg') center / contain no-repeat white;
    }
    &::after {
      @include pseudo();
      width: 100%;
      height: 100%;
      left: -38px;
      background: url('../../../assets/img/features/iphone.png') center / contain no-repeat;
      @include image-2x('../../../assets/img/features/iphone@2x.png');
    }
  }

  &-Preload {
    width: 0;
    height: 0;
  }

  &-Phone_active_1::before,
  &-Preload_1 {
    background-image: url('../../../assets/img/features/list@2x.jpg');
  }
  &-Phone_active_2::before,
  &-Preload_2 {
    background-image: url('../../../assets/img/features/search@2x.jpg');
  }
  &-Phone_active_3::before,
  &-Preload_3 {
    background-image: url('../../../assets/img/features/favourites@2x.jpg');
  }
  &-Phone_active_4::before,
  &-Preload_4 {
    background-image: url('../../../assets/img/features/orders@2x.jpg');
  }
  &-Phone_active_5::before,
  &-Preload_5 {
    background-image: url('../../../assets/img/features/map@2x.jpg');
  }
  &-Phone_active_6::before,
  &-Preload_6 {
    background-image: url('../../../assets/img/features/filters@2x.jpg');
  }
  &-Phone_active_7::before,
  &-Preload_7 {
    background-image: url('../../../assets/img/features/review@2x.jpg');
  }
  &-Phone_active_8::before,
  &-Preload_8 {
    background-image: url('../../../assets/img/features/sale-items@2x.jpg');
  }

  &-List {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &-Item {
    position: absolute;
    max-width: 160px;
    &:first-child {
      top: 12%;
      left: calc(50% - 380px);
    }
    &:nth-child(2) {
      top: 35%;
      left: calc(50% - 470px);
    }
    &:nth-child(3) {
      bottom: 34%;
      left: calc(50% - 470px);
    }
    &:nth-child(4) {
      bottom: 14%;
      left: calc(50% - 380px);
    }
    &:nth-child(5) {
      top: 10%;
      right: calc(50% - 380px);
    }
    &:nth-child(6) {
      top: 31%;
      right: calc(50% - 470px);
    }
    &:nth-child(7) {
      bottom: 39%;
      right: calc(50% - 470px);
    }
    &:last-child {
      bottom: 15%;
      right: calc(50% - 380px);
    }
  }

  &-ItemBlock {
    opacity: 1;
    cursor: default;
    transition: opacity $short $ease-in-out;
    .FeaturesList-List_active & {
      opacity: 0.5;
    }
    .FeaturesList-Item_active > &,
    &:hover {
      outline: none;
      opacity: 1;
    }
  }

  &-ItemLine {
    position: absolute;
    opacity: 0;
    transition: opacity $medium $ease-in-out;
    .FeaturesList-Item:first-child & {
      top: -7px;
      left: 66px;
    }
    .FeaturesList-Item_active:first-child & {
      opacity: 1;
    }
    .FeaturesList-Item:nth-child(2) & {
      top: -95px;
      left: 96px;
    }
    .FeaturesList-Item_active:nth-child(2) & {
      opacity: 1;
    }
    .FeaturesList-Item:nth-child(3) & {
      top: -193px;
      left: 133px;
    }
    .FeaturesList-Item_active:nth-child(3) & {
      opacity: 1;
    }
    .FeaturesList-Item:nth-child(4) & {
      top: -60px;
      left: 96px;
    }
    .FeaturesList-Item_active:nth-child(4) & {
      opacity: 1;
    }
    .FeaturesList-Item:nth-child(5) & {
      top: 13px;
      left: -179px;
    }
    .FeaturesList-Item_active:nth-child(5) & {
      opacity: 1;
    }
    .FeaturesList-Item:nth-child(6) & {
      top: -83px;
      left: -216px;
    }
    .FeaturesList-Item_active:nth-child(6) & {
      opacity: 1;
    }
    .FeaturesList-Item:nth-child(7) & {
      top: -4px;
      left: -278px;
    }
    .FeaturesList-Item_active:nth-child(7) & {
      opacity: 1;
    }
    .FeaturesList-Item:last-child & {
      top: -66px;
      left: -138px;
    }
    .FeaturesList-Item_active:last-child & {
      opacity: 1;
    }
  }

  &-ItemTitle {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: normal;
    color: $greyish-brown;
    line-height: 1;
    margin-bottom: $unit + $unit / 2;
  }

  &-ItemTitleText {
    margin-left: $unit;
  }

  &-ItemText {
    font-size: 12px;
    font-weight: 300;
    color: $brownish-grey;
  }
}
