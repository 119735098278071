@font-face {
  font-family: 'Merge';
  src: url('../assets/fonts/merge-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merge';
  src: url('../assets/fonts/merge.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merge';
  src: url('../assets/fonts/merge-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
