@import 'styles/variables';

.ContactForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-Submit {
    margin-top: $unit * 4;
    text-align: right;
  }

  &-Message {
    display: flex;
    align-items: center;
  }

  &-MessageText {
    margin-left: $unit;
  }

  &-Submit {
    width: 135px;
    align-self: flex-end;
    @media (max-width: 759px) {
      width: 100%;
    }
  }
}
