@import 'styles/variables';
@import 'styles/mixins';

.Contacts {
  padding-top: 16px;
  margin-top: 115px;
  padding-left: $padding;
  padding-right: $padding;
  @media (max-width: 759px) {
    padding-right: $mobile-padding;
    padding-left: $mobile-padding;
  }

  &-Wrapper {
    border-radius: 4px;
    max-width: 760px;
    margin: auto;
    position: relative;
    background-image: radial-gradient(circle at 31% 31%, $dull-pink, $mauve);
    &::before {
      @include pseudo();
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url('../../assets/img/contacts/light.svg') top center / contain no-repeat;
    }
    &::after {
      @include pseudo();
      width: 802px;
      height: 288px;
      bottom: -110px;
      left: 50%;
      transform: translateX(-50%);
      background: url('../../assets/img/contacts/shadow.svg') center / contain no-repeat;
      @media (max-width: 759px) {
        bottom: -110px;
        width: 320px;
        height: 260px;
        background-image: url('../../assets/img/contacts/shadow_sm.svg');
      }
    }
  }

  &-Ears {
    position: absolute;
    top: -51px;
    left: 50%;
    transform: translateX(-50%);
  }

  &-Content {
    z-index: 1;
    position: relative;
    color: white;
    padding: 66px 60px;
    display: flex;
    @media (max-width: 759px) {
      flex-direction: column;
      padding-left: $mobile-padding;
      padding-right: $mobile-padding;
      padding-bottom: $padding;
    }
  }

  &-Block {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:first-child {
      margin-right: $unit * 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &:last-child {
      margin-left: $unit * 3;
    }
    @media (max-width: 759px) {
      width: 100%;
      &:first-child {
        margin-right: 0;
      }
      &:last-child {
        margin-left: 0;
      }
    }
  }

  &-Text {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 32px;
    & > b {
      font-weight: 300;
    }
    @media (max-width: 759px) {
      text-align: center;
      margin-bottom: $unit * 3;
    }
  }

  &-List {
    display: flex;
  }

  &-ListItem {
    &:not(:last-child) {
      margin-right: $unit * 2;
    }
  }

  &-Link {
    color: white;
    transition: color $shortest $ease-in-out;
    &:hover,
    &:focus {
      outline: none;
      color: $black;
    }
  }

  &-Title {
    font-size: 16px;
    letter-spacing: 0.8px;
    font-weight: normal;
    text-transform: uppercase;
    color: rgba(white, 0.7);
    margin-top: 16px;
    margin-bottom: $unit;
  }
}
