@import 'styles/variables';
@import 'styles/mixins';

.Footer {
  position: relative;

  &-Content {
    display: flex;
    align-items: center;
    padding-bottom: $unit * 8;
    @include xs {
      flex-direction: column;
    }
  }

  &-Text {
    width: 33.3%;
    font-size: 10px;
    font-weight: 300;
    color: rgba(white, 0.5);
    order: 1;
    @include xs {
      width: 100%;
      text-align: center;
    }
    &:nth-child(2) {
      text-align: center;
      @include xs {
        order: 0;
        margin-bottom: $unit * 2;
      }
    }
    &:last-child {
      text-align: right;
      @include xs {
        text-align: center;
        margin-top: $unit * 2;
      }
    }
  }

  &-Shield {
    display: inline-block;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    padding: 0;
    border: 0;
    cursor: pointer;
    transition: transform $shortest $ease-in-out;
    background: url('../../assets/img/shield.png') center / contain no-repeat;
    @include image-2x('../../assets/img/shield@2x.png');
    &:hover,
    &:focus {
      outline: none;
      transform: scale(1.1);
    }
  }
}
